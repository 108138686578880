import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import "primevue/resources/themes/lara-light-indigo/theme.css";     
import './assets/css/bootstrap-grid.min.css'    
// //core
import "primevue/resources/primevue.min.css";
import './assets/css/fonts.css'
// //icons
import "primeicons/primeicons.css";

createApp(App).use(store).use(router).use(VueAxios, axios).use(PrimeVue).use(ToastService).mount('#app')
