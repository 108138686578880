<template>
  <div class="contactUs">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-5">
  				<img src="../assets/img/contactUs/contactUs.svg" alt="" class="contact-img">
  			</div>
  			<div class="col-md-6">
  				<div class="contact-box">
  					<div class="services-tittle">Свяжитесь с нами</div>
  					<div class="contact-text">Пожалуйста, оставьте ваш номер телефона. Мы гарантируем конфиденциальность ваших личных данных и не передадим их третьим лицам. Спасибо за вашу поддержку и доверие!</div>
  					<a href="#0" class="home_btn" @click="this.$store.state.feedback_form_toggle = true">Оставить номер</a>
  				</div>
  			</div>
  		</div>
  	</div>
  	<div class="after-footer">
  		<div class="container">
  			<div class="row">
  				<div class="col-md-12 center">
  					<h3 class="services-tittle white">Добейтесь большего успеха в своих проектах с нашей помощью</h3>
  					<a href="#0" class="home_btn_bottom" @click="this.$store.state.feedback_form_toggle = true">Начать</a>
  				</div>
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>
.white{
	color:#fff;
	background: inherit;
}
.contactUs{
	margin-top: 5%;
	position: relative;
}
.contact-box {
	margin-top: 10%;
}
.contact-img {
	width:100%;
	height:auto;
}
.contact-text {
	margin-top: 5%;
	line-height: 24px;
	font-size: 16px;
	color:#000;
}
.home_btn {
  padding: 13px 0px;
  margin-top: 5%;
  background-color:#444BD3;
  color:#fff;
  border-radius:6px;
  max-width: 220px;
  text-align: center;
  display:block;
  text-decoration: none;
  cursor: pointer;

}
.home_btn_bottom {
  padding: 13px 0px;
  margin-top: 5%;
  background-color:#444BD3;
  color:#fff;
  border-radius:6px;
  max-width: 220px;
  text-align: center;
  display:block;
  text-decoration: none;
  cursor: pointer;
  margin:0 auto;
}
.after-footer {
	background: linear-gradient(180deg, #2A1061 0%, #1B0B3D 100%);
	position: relative;
	width: 100%;
	clip-path: polygon(100% 40%, 100% 100%, 0 100%, 0 0);
	padding:5% 0;
	padding-top: 10%;
}
.center {
	text-align: center;
}
@media(max-width:991px){
	.home_btn {
    padding: 13px 0px;
  	}
  	.after-footer {
	background: linear-gradient(180deg, #2A1061 0%, #1B0B3D 118.18%);
	position: relative;
	width: 100%;
	clip-path: polygon(100% 40%, 100% 100%, 0 100%, 0 0);
	padding:5% 0;
	padding-top: 180px;
}
}
</style>

