<template>
  <div class="our_work" id="our_work">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h2 class="services-tittle">С чем мы работаем</h2>
  			</div>
  		</div>
  	</div>
  	<div class="box desctop" >
  		<div class="row">
  			<div class="col-md-12">
  				<swiper :slides-per-view="3" :loop="true" :breakpoints="{1000:{slidesPerView: 2, spaceBetween:-100},1100:{slidesPerView: 3, spaceBetween:-50}}" :space-between="-100" :modules="modules" :pagination="{ clickable: true }">
				    <swiper-slide v-for="(item, index) of data" :key="index">
				    	<div class="cart-box">
				    		<div class="cart-box-head">
				    			<div class="cart-box-head-logo">
				    				<img :src="require(`@/assets/img/our_work/${item.logo}`)" alt="" class="cart-box-head-img">
				    			</div>
				    			<div class="cart-box-head-tittle">{{item.tittle}}</div>
				    		</div>
				    		<div class="cart-box-body" >
				    			<div class="cart-box-body-text">{{item.description}}</div>
				    		</div>
				    		<div class="cart-liner"><div class="liner"></div><div class="liner-sec"></div></div>
				    		<div class="cart-box-footer">
				    			<div class="footer-tittle">Основные области применения:</div>
				    			<ul class='footer-list'>
				    				<li v-for="items of item.list" :key='index'>
				    				<i class="footer-ico"></i> 
				    				{{items.description}}
					    			</li>
					    		</ul>
				    			<a href="#0" class="menu-content-link" @click="this.$store.state.feedback_form_toggle = true">Узнать больше</a>
				    		</div>
				    	</div>
				    </swiper-slide>
				  </swiper>
  			</div>
  		</div>
  	</div>
  	<div class="box mobile">
  		<div class="row">
  			<div class="col-md-12">
  				<div class="mobile-box">
  					<div class="cart-box" v-for="(item, index) of data" :key="index">
				    		<div class="cart-box-head" @click="toggle(index)">
				    			<div class="cart-box-head-logo">
				    				<img :src="require(`@/assets/img/our_work/${item.logo}`)" alt="nimirum сайты боты парсеры" class="cart-box-head-img">
				    			</div>
				    			<div class="cart-box-head-tittle">{{item.tittle}}</div>
				    			<i class="arrow-mobile" :class="{'active':active == index}"></i>
				    		</div>
				    		<div class="cart-box-body" v-if="active == index">
				    			<div class="cart-box-body-text">{{item.description}}</div>
				    		</div>
				    		<div class="cart-liner" v-if="active == index"><div class="liner"></div><div class="liner-sec"></div></div>
				    		<div class="cart-box-footer" v-if="active == index">
				    			<div class="footer-tittle">Основные области применения:</div>
				    			<ul class='footer-list'>
				    				<li v-for="items of item.list" :key='index'>
				    				<i class="footer-ico"></i> 
				    				{{items.description}}
					    			</li>
					    		</ul>
				    			<a href="#0" class="menu-content-link" @click="this.$store.state.feedback_form_toggle = true">Узнать больше</a>
				    		</div>
				    	</div>
  				</div>
  			</div>
  		</div>
  	</div>
  	  
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import SwiperClass, { Pagination } from 'swiper'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export default {
  name:'',
  components: {
  	Swiper,
    SwiperSlide
  },
  data(){
  	return{
  		active:-1,
  		modules: [Pagination, Navigation],
  		data:[
  		{logo:'figma.svg', tittle:'Figma', description:'Инновационный и удобный инструмент для дизайнеров и разработчиков. С его помощью мы сможем создавать сложные и красивые дизайн-проекты.', 
  		list:[
  		{description:'Создание дизайн-макетов для веб-сайтов и мобильных приложений.'},
  		{description:'Разработка логотипов, баннеров, иконок и других элементов дизайна.'},
  		{description:'Создание интерактивных макетов с анимацией и переходами.'},
  		{description:'Совместное редактирование и ревью проектов с другими участниками.'},
  		]},

  		{logo:'python.svg', tittle:'Python', description:'Простой в использовании язык программирования, который широко используется в  веб-разработке, создании приложений и многих других областях.', 
  		list:[
  		{description:'Создание веб-приложений с использованием фреймворков Django или Flask.'},
  		{description:'Работа с базами данных с помощью ORM-библиотек, таких как SQLAlchemy.'},
  		{description:'Создание чат-ботов для мессенджеров, таких как Telegram и Slack, используя Telegram API и Slack API.'},
  		]},

  		{logo:'vue.svg', tittle:'Vue.js', description:'Помогает разработчикам создавать сложные пользовательские интерфейсы, которые хорошо адаптируются к мобильным устройствам и имеют высокую производительность.', 
  		list:[
  		{description:'Фронтенд-разработка веб-приложений.'},
  		{description:'Разработка мобильных приложений.'},
  		{description:'Разработка одностраничных приложений (SPA)'},
  		{description:'Создание интерактивных элементов пользовательского интерфейса.'},
  		]},

  		{logo:'jquery.svg', tittle:'jQuery', description:'JavaScript-библиотека, которая упрощает работу с JavaScript, обладает большой функциональностью и улучшенным синтаксисом.', 
  		list:[
  		{description:'JQuery облегчает изменение HTML-кода документа, добавление новых элементов'},
  		{description:'JQuery позволяет создавать обработчики событий, такие как щелчки мыши, нажатие клавиш и т.д.'},
  		{description:'Query упрощает отправку и получение данных с сервера, используя технологию AJAX.'},
  		]},

  		{logo:'nuxt.svg', tittle:'Nuxt.js', description:'фреймворк для разработки универсальных приложений на Vue.js. Он использует серверный рендеринг и генерацию статических сайтов', 
  		list:[
  		{description:'Создание дизайн-макетов для веб-сайтов и мобильных приложений.'},
  		{description:'в Nuxt.js также есть возможность генерации статических сайтов.'},
  		{description:'Nuxt.js также известен своими продвинутыми функциями, которые могут быть полезными при разработке проектов на высокой скорости.'},
  		]},

  		{logo:'yii.svg', tittle:'Yii 2.0', description:'Современный PHP-фреймворк для создания веб-приложений. Обладает широкими возможностями и использует передовые технологии', 
  		list:[
  		{description:'Предоставляет множество инструментов, специально разработанных для работы с электронной коммерцией'},
  		{description:'Использоваться для создания социальных сетей любого размера.'},
  		{description:'Может быть использован для создания систем управления содержанием (CMS)'},
  		]},

  		{logo:'cordova.svg', tittle:'Cordova', description:'Фреймворк для мобильных приложений, который позволяет разрабатывать кроссплатформенные приложения с помощью HTML, CSS и JavaScript.', 
  		list:[
  		{description:'Сordova позволяет создавать кроссплатформенные мобильные приложения на основе веб-технологий.'},
  		{description:'Позволяет быстро создавать прототипы мобильных приложений для проверки идеи на практике.'},
  		{description:'Позволяет быстро создавать мобильные приложения для внутреннего использования в предприятии.'},
  		]}
  		],
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	toggle(index){
  		if(this.active != index){
  			this.active = index
  		} else {
  			this.active = -1
  		}
  	},
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>
.our_work {
	padding:5% 0;
}

.cart-box {
	padding:20px;
	background: #FFFFFF;
	border-radius: 20px;
	max-width: 260px;
	margin-bottom: 5%;
}
.cart-box-head {
	display:flex;
	align-items: center;
}
.cart-box-head-logo {
	height:70px;
	width:70px;
	display: flex;
	background: #1E1E1E;
	border-radius: 10px;
}
.cart-box-head-img {
	display: block;
    margin:auto;
}
.cart-box-head-tittle {
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	color:#000;
	margin-left:40px;
}
.cart-box-head-text {

}
.cart-box-body {

}
.cart-box-body-text {
	margin-top:14px;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
	padding-bottom:28px;
	position: relative;
	max-width: 90%;
}
@keyframes sweathc {
from {
	margin-left:-100%;

}

to {
	margin-left:100%;

}
}
@keyframes sweathcS {
from {
	margin-left:-200%;
	animation-timing-function: linear;
}

to {
	margin-left:100%;
	animation-timing-function: linear;
}
}
.cart-liner{
	overflow: hidden;
	height:2px;
	width:100%;
	position: relative;
	align-items: center;
	
}
.liner{
	background: linear-gradient(90deg,rgba(42, 16, 97, 0)  0%, #2A1061  100%);
	height:2px;
	position: relative;
	top:0px;
	z-index: 0;
	width:100%;
	display:block;
	animation: sweathcS  2s infinite linear;
}
/*.liner-sec{
	background: linear-gradient(90deg, #2A1061 0%, rgba(42, 16, 97, 0) 100%, );
	height:2px;
	width:100%;
	display:block;
	position: relative;
	top:0px;

	
}*/
/*.cart-box-body-text:after{
	content:'';
	position:absolute;
	overflow: hidden;
	display:block;
	left:-100%;
	height:2px;
	bottom:5px;
	padding-left:15px;
	animation: sweathc  2s infinite linear;
	width:100%;
	background: linear-gradient(90deg, rgba(42, 16, 97, 0) 0%, #2A1061 100%);
}*/
.swiper-horizontal >>> .swiper-pagination-bullet {
    opacity: 0.5;
    background-color: #fff;
    height:12px;
    width:12px;
}
.swiper-horizontal >>> .swiper-pagination-bullet-active {
	opacity: 1;
    background-color: #fff;
}
.swiper-horizontal >>> .swiper-pagination-clickable {
	bottom:0px;
}
.cart-box-footer {
	margin:10px 0px;
}
.footer-tittle {
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
	color:#000;
}
.footer-list {
	padding-left:20px;
	list-style-type: none;
	max-width: 80%;
}
.footer-list li {
	font-size:15px;
	position: relative;
	margin:5px 0px;
}
.footer-ico {
	width:12px;
	height: 8px;
	display:block;
	position: absolute;
	left:-20px;
	top:7px;
	background:url('../assets/img/our_work/arrow-list.svg');
	background-size: contain;
	background-repeat:no-repeat;
}
.footer-btn {

}
.menu-content-link {
	cursor:pointer;
	text-decoration: none;
	color:#000;
	position: relative;
}
.menu-content-link:before{
	content:'';
	position: absolute;
	background:url('../assets/img/arrow.svg');
	display:block;
	width:20px;
	height: 10px;
	right:-30px;
	top:5px;
}
.desctop {
	display:block;
}
.mobile{
	display:none;
}
@media(max-width:1000px){
	.desctop {
		display:none;
	}
	.mobile{
		display:block;
	}
	.mobile-box .cart-box {
		width:100%;
		max-width: 95%;
		position: relative;
		z-index: 1;
	}
	.mobile-box .cart-box-head {
		position: relative;
		cursor: pointer;
	}
	.arrow-mobile{
		width:20px;
		height:12px;
		display:block;
		position: absolute;
		background: url('../assets/img/our_work/arrow_mobile.svg');
		background-repeat: no-repeat;
		background-size: contain; 
		right:10px;
		transition:all 500ms ease;
		transform: rotate(90deg);
	}
	.arrow-mobile.active{
		width:20px;
		height:12px;
		display:block;
		position: absolute;
		background: url('../assets/img/our_work/arrow_mobile.svg');
		background-repeat: no-repeat;
		background-size: contain; 
		right:10px;
		transition:all 500ms ease;
		transform: rotate(0deg);
	}
}
</style>

