<template>
  <div class="our_skill">
  	    <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="h1_first">Мы готовы принимать на выполнение различные
заказы в области IT и веб-разработки.</h1>
      </div>
      <div class="col-md-6">
        <div class="box box_1">
          <div class="inside_first_i_1"></div>
          <p class="text_p_1">Разработка приложений для мобильных устройств</p>
          <p class="text_p_2"> Мы предлагаем профессиональную разработку мобильных приложений для платформ iOS, Android и Windows. Наши специалисты выполнят проект любой сложности: от простого калькулятора до сложного бизнес-приложения.</p>
          <a class="under_btn" @click="this.$store.state.feedback_form_toggle = true"><div class="under_btn_icon"></div></a>
      </div>
      </div>
      <div class="col-md-6">
        <div class="box box_2 mt-5">
          <div class="inside_first_i_2"></div>
          <p class="text_p_1">Создание программного обеспечения для бизнеса</p>
          <p class="text_p_2">Наша компания предлагает профессиональное создание программного обеспечения, подходящего именно под ваш бизнес. Мы заботимся о наших клиентах и работаем с ними на каждом этапе проекта, чтобы гарантировать, что результат соответствует всем вашим требованиям и ожиданиям.</p>
          <a class="under_btn" @click="this.$store.state.feedback_form_toggle = true"><div class="under_btn_icon"></div></a>
      </div>
      </div>
      <div class="col-md-6">
        <div class="box box_3 mt_5">
          <div class="inside_first_i_3"></div>
          <p class="text_p_1">Веб-разработка и создание сайтов под ключ</p>
          <p class="text_p_2"> Мы занимаемся не просто созданием сайтов, мы создаем эффективные инструменты для вашего бизнеса. Направьте поток клиентов на свой сайт с нашими профессиональными решениями.</p>
          <a class="under_btn" @click="this.$store.state.feedback_form_toggle = true"><div class="under_btn_icon"></div></a>
      </div>
      </div>
      <div class="col-md-6">
        <div class="box box_4 mt-10">
          <div class="inside_first_i_4"></div>
          <p class="text_p_1">Разработка систем управления контентом</p>
          <p class="text_p_2">Системы управления контентом позволяют пользователям без специальных знаний и навыков веб-разработки создавать, изменять и управлять содержимым на своих сайтах. Это могут быть тексты, фотографии, видео, аудио или любые другие элементы, необходимые для веб-сайта.</p>
          <a class="under_btn" @click="this.$store.state.feedback_form_toggle = true"><div class="under_btn_icon"></div></a>
      </div>
      </div>
    </div>
  </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>
/*****box*****/
.mt_5 {
  margin-top: -18%;
}
.mt-5 {
  margin-top:30%;
}
.mt-10 {
  margin-top: 13%;
}
.under_btn:hover .under_btn_icon {
  background: url('@/assets/img/our_skill/longarrow-right_dark.svg');
  transition: all 500ms ease;
}
.under_btn:hover {
  background-color:#fff;
  transition: all 500ms ease;
}
.under_btn_icon {
  background: url('@/assets/img/our_skill/longarrow-right.svg');
  background-size:100%;
    background-repeat: no-repeat;
  width:30px;
  height:30px;
  transition: all 500ms ease;
}
.under_btn {
  width:97px;
  height:57px;
  border:1px #fff solid;
  border-radius:30px;
  display: block;
  margin-top:30px;
  padding:14px 34px;
  transition: all 500ms ease;
}
.text_p_2 {
  font-size:15px;
  font-weight:400;
  color:#fff;
  margin:0;
}
.text_p_1 {
  font-size:27px;
  font-weight:600;
  margin:70px 0 25px 0;
  color:#fff;
}
.inside_first_i_1 {
  background: url('@/assets/img/our_skill/inside_1.svg');
  background-size:100%;
    background-repeat: no-repeat;
  width:130px;
  height:123px;
}
.inside_first_i_2 {
  background: url('@/assets/img/our_skill/inside_2.svg');
  background-size:100%;
    background-repeat: no-repeat;
  width:130px;
  height:92px;
}
.inside_first_i_3 {
  background: url('@/assets/img/our_skill/inside_3.svg');
  background-size:100%;
  background-repeat: no-repeat;
  width:130px;
  height:123px;
}
.inside_first_i_4 {
  background: url('@/assets/img/our_skill/inside_4.svg');
  background-size:100%;
    background-repeat: no-repeat;
  width:130px;
  height:123px;
}
.all_container {
  -webkit-box-pack: center;
  justify-content: center;
}
.box_1 {
  background:#3C2F58 url('@/assets/img/our_skill/under_1.svg');
  background-size:100%;
    background-repeat: no-repeat;
}

.box_2 {
  background:#C0617E url('@/assets/img/our_skill/back_2.svg');
  background-size:100%;
    background-repeat: no-repeat;
}
.box_3 {
  background:#A15757 url('@/assets/img/our_skill/under_3.svg');
  background-size:100%;
    background-repeat: no-repeat;
}
.box_4 {
  background:#48564D url('@/assets/img/our_skill/under_4.svg');
  background-size:100%;
  background-repeat: no-repeat;
}
.box {
  width:510px;
  height:610px;
  border-radius:20px;
  padding:50px;
  margin-right: 15px;
  margin-left: 15px;
}

/*****box*****/


.h1_first {
  font-size:40px;
  max-width:1100PX;
  margin:100px 0 70px 20px;
  color:#fff;
}
@media(max-width:1200px){
  .box {
    width:100%;
  }
  .text_p_1 {
    margin-top:20px;
  }
}
@media(max-width:991px){
  .text_p_1 {
    font-size:20px;
  }
  .box {
    padding:20px;
    height: 526px;
  }
  .h1_first {
    font-size:30px;
  }
}
@media(max-width:767px){
  .h1_first {
    margin:30px 0 20px 0;
  }
.mt_5 {
  margin-top: 0;
}
.mt-5 {
  margin-top:0;
}
.mt-10 {
  margin-top: 0;
}
.box {
  margin-top:5%;
}
}
@media(max-width:767px){
  .box {
    margin-right:0;
    margin-left:0;
    height: auto;
  }
}
</style>

