<template>
  <div class="politic">
  	<Dialog v-model:visible="this.$store.state.show_politic" modal header="Пользовательское Соглашение" :style="{ width: '100vw' }">
<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h1>Пользовательское Соглашение</h1>
<p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между Карегиной Екатериной Викторовной (далее veles-groom.ru или Администрация) с одной стороны и пользователем сайта с другой.<br>
Сайт veles-groom.ru не является средством массовой информации.</p>

<p>Используя сайт, Вы соглашаетесь с условиями данного соглашения.<br>
Если Вы не согласны с условиями данного соглашения, не используйте сайт veles-groom.ru!</p>

<h1>Права и обязанности сторон</h1>
<p><b>Пользователь имеет право:</b><br>
- осуществлять поиск информации на сайте<br>
- получать информацию на сайте<br>
- комментировать контент, выложенный на сайте<br>
- копировать информацию на другие сайты с указанием источника<br>
- копировать информацию на другие сайты с разрешения Администрации сайта<br>
- использовать информацию сайта в личных некоммерческих целях</p>

<h1>Администрация имеет право:</h1>
<p>- по своему усмотрению и необходимости создавать, изменять, отменять правила<br>
- ограничивать доступ к любой информации на сайте<br>
- создавать, изменять, удалять информацию<br>
- удалять учетные записи<br>
- отказывать в регистрации без объяснения причин</p>

<h1>Пользователь обязуется:</h1>
<p>- не нарушать работоспособность сайта
- не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу<br>
- не совершать действия, направленные на введение других Пользователей в заблуждение<br>
- не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам<br>
- не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ<br>
- не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ<br>
- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами</p>

<h1>Администрация обязуется:</h1>
<p>- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.<br>
- предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом
</p>

<h1>Ответственность сторон</h1>
<p>- пользователь лично несет полную ответственность за распространяемую им информацию<br>
- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами<br>
- в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
</p>
<h1>Условия действия Соглашения</h1>
<p>Данное Соглашение вступает в силу при любом использовании данного сайта.<br>
Соглашение перестает действовать при появлении его новой версии.<br>
Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.<br>
Администрация не оповещает пользователей об изменении в Соглашении.</p>
  			</div>
  		</div>
  	</div>
	</Dialog>
  	
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import Dialog from 'primevue/dialog';
export default {
  name:'',
  components: {
  	Dialog,
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>

</style>

