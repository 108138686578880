import { createStore } from 'vuex'

export default createStore({
  state: {
    feedback_form_toggle:false,
    show_politic:false,
  },
  getters: {
  },
  mutations: {
    FEEDBACK_FORM(state){
    state.feedback_form_toggle = !state.feedback_form_toggle
    },
  },
  actions: {
  },
  modules: {
  }
})
