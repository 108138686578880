<template>
  <div class="box_success">
  	<div class="container">
  		<div class="row">
  			
  			<div class="col-md-12 col-xl-6 ">
  				<div class="services-tittle">Выберите нас для вашего успеха</div>
  				<div class="sucsess-text">Выберите наc, чтобы получить самую свежую информацию, инновационные услуги и завоевать знания, необходимые для достижения успеха в IT. Мы готовы помочь вам воплотить в жизнь любую вашу идею и сделать технологическое будущее светлее.</div>
  				<div class="sucsess-text">Мы предоставляем услуги для компаний, которые хотят создать эффективные и современные сайты, приложения и программные решения. Наши опытные разработчики используют самые передовые технологии и инструменты, чтобы создавать решения высокого качества, соответствующие вашим потребностям.</div>
  			</div>
  			<div class="col-md-8 offset-md-2 col-xl-5 offset-xl-1">
  				<img class="success_img" src="../assets/img/box_success/success_girl.svg" alt="Выберите нас для вашего успеха">
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>
.box_success {
	position: relative;
	display:flex;
	align-items: center;
	z-index: 0;
}
.box_success:before {
	content:'';
	position: absolute;
	width:100%;
	height:250%;
	background: linear-gradient(180deg, #2A1061 -0.03%, #1B0B3D 99.97%);
	transform: skewY(15deg);
	z-index: -1;

}
.success_img {
	width: 100%;
	height:auto;
}
.services-tittle  {
	color: #FFFFFF;
}
.sucsess-text{
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color:#fff;
	margin:5% 0px;
}
@media(max-width:1000px){
	.box_success:before {
	content:'';
	position: absolute;
	width:100%;
	height:200%;
	background: linear-gradient(180deg, #2A1061 -0.03%, #1B0B3D 99.97%);
	transform: skewY(15deg);
	z-index: -1;

}
.box_success {
	padding:5% 0;
}
}
</style>

