<template>
  <navigation/>
  <feedback_form/>
  <Toast/>
  <router-view ></router-view>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import navigation from '@/components/navigation.vue'
import home from '@/views/HomeView.vue'
import feedback_form from '@/components/feedback_form.vue'
import Toast from 'primevue/toast';

export default {
  name:'',
  components: {
  navigation,
  home,
  feedback_form,
  Toast,
  },
  data(){
    return{
    }
  },
  mounted(){
    const anchors = document.querySelectorAll('a[href*="#"]')

    for (let anchor of anchors) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        
        const blockID = anchor.getAttribute('href').substr(1)
        
        document.getElementById(blockID).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      })
    }
  },
  created(){
    
  },
  methods:{
    ckeck(items){
      console.log(items)
    },
    ...mapMutations({
  }),
  ...mapActions({
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
};
</script>
<style>
#app {
  font-family: 'Montserrat';
  text-align: left;
  color: #2c3e50;
  background:linear-gradient(180deg, #FFFFFF 26.09%, #D9D8FC 100%);
}
body::-webkit-scrollbar {
  width: 8px;               
}
body::-webkit-scrollbar-track {
  background: linear-gradient(to left bottom,#dee4f3,#e7eaf6,#f0f1f9,#f8f8fc,#fff);        
}
body::-webkit-scrollbar-thumb {
  background-color: #444BD3;    
  border-radius: 20px;        
}
body{
  margin:0px;
  padding:0px;
}
.flex-center{
  display:flex;
  align-items: center;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin:0 ;
}
.box {
  max-width: 1262px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px 0 15px;
}
a {
  cursor:pointer;
}
.flex {
  display:flex;
}
.justify-content {
  justify-content: space-between;
}
.black {
  background:linear-gradient(180deg, #1D1959 0%, #0B083B 100%);
}
.black .text-color {
  color:#fff;
}
.white {
  background:linear-gradient(180deg, #1D1959 0%, #0B083B 100%);
}
.white .text-color {
  color:#000;
}
.black .background_gradient {
  background:linear-gradient(180deg, #1D1959 0%, #0B083B 100%);
  
}
.white .background_gradient {
  
  
}
.magasin_head_h3 {
  font-size:40px;
  margin:0;
}
.services-tittle {
  color:#111111;
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
}
@media(max-width:767px) {
  .magasin_head_h3 {
    font-size: 33px;
  }
  .services-tittle {
  font-weight: 600;
  font-size: 33px;
  line-height: 140%;
}
}
</style>
