<template>
  <div class="our_services" id="our_services">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h1 class="services-tittle">Наши услуги</h1>

  				<div class="box-services-destop">
  					<div class="menu-box">
  						<div class="menu-list-items " :class="{'items-active':active == index}" v-for="(item, index) of listItems" :key="index" @click="active = index">
  							<div class="menu-items ">
  								<img class="img-items" :src="require(`@/assets/img/services/${item.img}`)" alt="nimirum сайты боты парсеры">
  								<p class="text-items">{{item.description}}</p>
  							</div>
  						</div>
  					</div>

  						
  					<div class="menu-content-box">
  						<div class="row">
  							<div class="col-md-5">
  								<img :src="require(`@/assets/img/services/servicesDesc/${this.contentList.img}`)" alt="" class="menu-content-img">
  							</div>
  							<div class="col-md-6 offset-md-1">
								<div class="menu-content-box-description">
		  							<h3 class="menu-content-tittle">{{this.contentList.tittle}}</h3>
		  							<p class="menu-content-text">{{this.contentList.description}}</p>
		  							<a href="#0" class="menu-content-link" @click="this.$store.state.feedback_form_toggle = true">Узнать больше</a>
	  							</div>
  							</div>
  						</div>
  					</div>

  				</div>


  				<div class="box-services-mobile">
  					<div class="menu-box">
  						<div class="menu-list-items " :class="{'items-active':active == index}" v-for="(item, index) of listItems" :key="index" @click="active = index">
  							<div class="menu-items">
  								<i class="ico-arrow" :class="{'active':active == index}"></i>  								
  								<p class="text-items">{{item.description}}</p>
  								<img class="img-items" :src="require(`@/assets/img/services/${item.img}`)" alt="сайты">
  							</div>
  						
  					<div class="menu-content-box" v-if="active == index">
  						<div class="row">
  							<div class="col-md-6 ">
								<div class="menu-content-box-description">
		  							<p class="menu-content-text">{{this.contentList.description}}</p>
		  							<a href="#0" class="menu-content-link" @click="this.$store.state.feedback_form_toggle = true">Узнать больше</a>
	  							</div>
  							</div>
  							<div class="col-md-5">
  								<img :src="require(`@/assets/img/services/servicesDesc/${this.contentList.img}`)" alt="" class="menu-content-img">
  							</div>
  						</div>
  					</div>

  						</div>
  					</div>
  					
  				</div>
  			</div>
  		</div>
  	</div>
  </div>
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  		listItems:[
  		{id:1, img:'Vector.svg', description:'Сайты'},
  		{id:2, img:'Vector1.svg', description:'Приложения'},
  		{id:3, img:'Group.svg', description:'Дизайны'},
  		{id:4, img:'Vector2.svg', description:'Боты'},
  		{id:5, img:'iconfinder.svg', description:'Парсеры'},
  		{id:6, img:'emblem.svg', description:'Под ключ'},
  		],
  		listContent:[
  		{id:1, img:'undraw_online_stats_0g94.svg', tittle:'Сайты', description:'Мы создаем высококачественные и индивидуальные сайты и приложения, адаптированные к вашему бизнесу. Мы используем самые современные технологии и следим за последними тенденциями в дизайне. Наша команда готова взять на себя любые задачи и справиться с любыми требованиями. Свяжитесь с нами сегодня и начнем работу над вашим проектом!'},
  		{id:2, img:'undraw_investor_update_re_qnuu.svg', tittle:'Приложения', description:'Мы создаем приложения для различных бизнес-целей. Наши продукты могут помочь управлять небольшим бизнесом, следить за финансовыми показателями, упрощать коммуникацию внутри компании и улучшать маркетинг.'},
  		{id:3, img:'2chela_sidyat.svg', tittle:'Дизайны', description:'Хотите добавить оригинальности в свой бизнес или проекты? Наши уникальные дизайны помогут вам выделиться на фоне конкурентов и привлечь внимание потенциальных клиентов. С нашими дизайнами вы сможете создать свой собственный стиль и передать свои идеи в жизнь.'},
  		{id:4, img:'undraw.svg', tittle:'Боты', description:'Устали тратить время на рутинные задачи в интернете? Воспользуйтесь нашими ботами и наслаждайтесь свободным временем! Наши боты умеют делать все, от поиска информации до отправки сообщений и комментариев.'},
  		{id:5, img:'undraw_artificial_intelligence_re_enpp.svg', tittle:'Парсеры', description:'Утомились от ручного сбора данных? Наш парсер справится с этим заданием в разы быстрее и точнее! Не тратьте свое время на монотонную работу - доверьтесь нашему парсеру и наслаждайтесь результатами.'},
  		{id:6, img:'Chel_stoit.svg', tittle:'Под ключ', description:'Наши сайты под ключ - это гарантия вашего успеха. Мы не только создаем сайты, но и разрабатываем стратегию продвижения, чтобы ваш бизнес стал еще более заметным в интернете.'},
  		],
  		active:0,
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
	contentList:function(){
		return this.listContent[this.active]
	}
  } 
};
</script>

<style scoped>
.our_services {
	padding:5% 0 0 0;
}

.box-services-destop {
	display:block;
}
.menu-box {
	box-shadow: 0px 4px 20px 2px #DDE4FF;
	border-radius: 10px;
	padding:10px 50px;
	display: flex;
    align-items: center;
    justify-content: space-between;
    background:#fff;
}
.menu-list-items {
	display: block;
}
.menu-items {
	display:flex;
	flex-direction: column;
	cursor:pointer;
}
.img-items {
	max-height: 39px;
}
.text-items {
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 10px;
	color:#111111;
	margin:15px 0px;
}
.items-active {
	position: relative;
}
.items-active:after {
	content:'';
	position: absolute;
	display:block;
	width:100%;
	height:5px;
	bottom:-10px;
	background:#111111;
}
.menu-content-box {
	margin-top:5%;
	min-height: 400px;
}
.menu-content-img {
	width:100%;
	height:auto;
}
.menu-content-tittle {
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}
.menu-content-text {
	font-size: 16px;
	color: #000000;
}
.menu-content-link {
	cursor:pointer;
	text-decoration: none;
	color:#000;
	position: relative;
}
.menu-content-link:before{
	content:'';
	position: absolute;
	background:url('../assets/img/arrow.svg');
	display:block;
	width:20px;
	height: 10px;
	right:-30px;
	top:5px;
}
.box-services-mobile {
	display:none;
}

@media(max-width:1000px){
.our_services {
background: url(../assets/img/Rectangle.svg);
background-size:contain;
background-repeat: no-repeat;
}	
.box-services-mobile {
	display:block;
}
.box-services-destop {
	display:none;
}
.box-services-mobile .menu-content-box {
	min-height: 300px;
}
.box-services-mobile .menu-content-img {
	margin-top:5%;
	margin-bottom: 5%;
}

.box-services-mobile .menu-box {
	box-shadow: none;
    border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    position: relative;
    background:inherit;
}
.box-services-mobile .menu-list-items {
	display: block;
}
.box-services-mobile .menu-items {
	display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    margin:8px 0px;
}
.box-services-mobile .img-items {
	max-height: 39px;
	margin-left:15px;
}
.box-services-mobile .text-items {
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 10px;
	color:#111111;
	margin:15px 0px;
}
.box-services-mobile .items-active {
	position: relative;
}
.box-services-mobile .items-active:after {
	content:'';
	position: absolute;
	display:block;
	width:100%;
	height:0px;
	bottom:0px;
	background:#111111;
}
.ico-arrow {
	height:8px;
	width:16px;
	background:url(../assets/img/arrow1.svg);
	background-size:cover;
	background-repeat: no-repeat;
	transform:rotate(-90deg);
	margin-right:10px;
	transition: all 500ms ease;
}
.box-services-mobile .ico-arrow.active {
	height:8px;
	width:16px;
	background:url(../assets/img/arrow1.svg);
	background-size:cover;
	background-repeat: no-repeat;
	transform:rotate(0deg);
	margin-right:10px;
	transition: all 500ms ease;
} 
}
</style>

