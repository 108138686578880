<template>
  <div class="information">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h1 class="services-tittle">Разработка</h1>
  			</div>
  			<div class="col-md-5">
  				<div class="information-tittle">Технологии разработки</div>
  				<div class="information-text">Наша команда использует самые современные и надежные технологии, чтобы обеспечить высокую производительность и удобство использования наших сайтов и приложений. Мы также всегда следим за последними тенденциями в дизайне и технологиях и применяем их в нашей работе.</div>
  				<img src="../assets/img/information/Group.svg" alt="" class="information-img">
  			</div>
  			<div class="col-md-5 offset-md-2">
  				<div class="information-tittle">Любая сложность задач</div>
  				<div class="information-text">Мы готовы взять любые сложные задачи и справиться с любыми требованиями наших клиентов. Независимо от того, нужен вам профессиональный сайт-визитка или крупный корпоративный проект, мы уверены, что наша команда справится с задачей на 100%.</div>
  				<img src="../assets/img/information/Group1.svg" alt="" class="information-img">
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'',
  components: {
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>

.information-tittle {
	font-weight: 700;
	font-size: 20px;
	line-height: 140%;
	color: #444BD3;
	margin-bottom:10px;
}
.information-text{
	font-weight: 400;
	font-size: 16px;
	line-height: 180%;
	color: #121127;
	flex: none;
	order: 1;
	flex-grow: 0;
}
.information-img {
	width:100%;
	height:auto;
}
</style>

