<template>
  <div id="navigation" >     
        <div class="container-fluid container_padding" :class="{lose: isScrollPosition}"> 
          <div class="box "> 
          <div class="row">
            <div class="col-md-12">
                <router-link to="/"><!--<img :src="require('@/img/black-logo.png')" class="header_logo" alt="">--><div class="logo">Nimirum</div></router-link>
                <button @click="isOpenMenu = !isOpenMenu" :class="{opened: isOpenMenu}" class="nav-toggle open_menu">
                  <span class="bar-top"></span>
                  <span class="bar-mid"></span>
                  <span class="bar-bot"></span>
                </button>
                <div class="menu_box">  
                <ul :class="{'active': isOpenMenu}">
                    <li @click="isOpenMenu = !isOpenMenu"><a href="#our_services" class="text-color">Услуги</a></li>
                    <li @click="isOpenMenu = !isOpenMenu"><a href="#our_work" class="text-color">Наши навыки</a></li>

                    <li @click="isOpenMenu = !isOpenMenu"><router-link  to="/"><a class="text-color">ProductPage</a></router-link></li>

                    <li @click="isOpenMenu = !isOpenMenu"><router-link  to="/"><a class="text-color">ImprovementsPage</a></router-link></li>
                    <li @click="isOpenMenu = !isOpenMenu"><router-link  to="/"><a class="text-color">CmsSistems</a></router-link></li>
                    <a class="btn_header btn_header_desk" @click="this.FEEDBACK_FORM" :class="{btn_header_nav:isScrollPosition}">Консультация</a>
                    <a class="btn_header btn_header_mobile" @click="this.FEEDBACK_FORM" :class="{btn_header_nav:isScrollPosition}">Консультация</a> 
                </ul>
                </div>
            </div>  
          </div>
          <div class="arrow-up" v-if="isScrollPosition" @click='goToTop()'>
            <div class="arrow"><svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.72882 5.27118C9.03392 5.57627 9.52858 5.57627 9.83368 5.27118C10.1388 4.96608 10.1388 4.47142 9.83368 4.16632L5.92743 0.260073C5.62233 -0.0450249 5.12767 -0.0450249 4.82257 0.260073L0.916323 4.16632C0.611226 4.47142 0.611226 4.96608 0.916323 5.27118C1.22142 5.57627 1.71608 5.57627 2.02118 5.27118L4.59375 2.6986L4.59375 16.4375C4.59375 16.869 4.94353 17.2188 5.375 17.2188C5.80647 17.2188 6.15625 16.869 6.15625 16.4375L6.15625 2.6986L8.72882 5.27118Z" fill="black"/>
            </svg></div>
          </div>
        </div>
        </div>
  </div>
</template>

<script>

import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

  export default {
    name:'navigation',
    data(){
      return {
        isOpenMenu:false,
        isScrollPosition: false,
        scroll:0,
      }
    },
    mounted(){
      window.addEventListener('scroll',() => this.newPosition(scrollY))
    },
    methods:{
      ...mapMutations({
        FEEDBACK_FORM:'FEEDBACK_FORM'
      }),
      newPosition(scroll){
        scroll > 500 ? this.isScrollPosition = true : this.isScrollPosition = false
      },
      goToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    computed:{
    }
    };
</script>

<style scoped>
.container_padding {
  padding-top:10px;
  padding-bottom:10px;
}
.navigation {
  height: 100px;
}
.arrow-up {
  width: 45px;
  height: 45px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(68, 75, 211, 0.25);
  display: flex;
  align-items: center;
  border-radius: 100px;
  position: fixed;
  bottom:50px;
  right:30px;
  cursor:pointer;
}
.arrow {
    display: block;
    margin: 0 auto;
}
.logo{
  color: #121127;
    text-decoration: none;
    font-size: 28px;
    position: relative;
    float: left;
    bottom: -7px;
    border-top: 1px solid #121127;
    border-bottom: 1px solid #121127;
    text-transform: uppercase;
    line-height: 44px;
}
.btn_header_mobile {
  display:none;
}
.btn_header{
  font-size:16px;

}
.header_logo {
    width: 150px;
    height: 35px;
    margin-top: 14px;
    float: left;

}
  #navigation {
  position:relative;
    background-size:content;
    background-repeat: no-repeat;
    background:#fff;
}
#navigation ul {
    list-style-type: none;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
#navigation li {
    display: inline-block;
    margin: 0px 30px 0px 0px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    height:26px;
}
#navigation ul li a {
  text-decoration:none;
  color:#121127;
  font-size:16px;
  padding: 0px;
    line-height: 22px;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    padding-top: 4px;
}
#navigation ul li a:hover:before {
      transition:all 500ms ease;
      width:100%;
}
#navigation ul li a:before {
  content: "";
    position: absolute;
    display: block;
    top: 0;
    width: 0%;
    height: 3px;
    transition: all 500ms ease;
    background-color: #121127;
}
.btn_header {
    transition:all 500ms ease;
    float: right;
    text-align: center;
    padding: 8px 32px;
    background: #444BD3;
    border-radius: 4px;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
    margin-top:0;
    font-size: 16px;
}
.btn_header:hover {
    background:#444BD3;
    color:#fff;
} 
  .btn_header_nav {
    background:#444BD3;
    color:#fff;
  }
  .lose {
    transition:all 500ms ease;
    position:fixed;
    z-index:15;
    width:100%;

    top:0;
    background:#fff;
  }
  .menu{
    position:relative;
  }
.nav-toggle {
  position:absolute;
  display:none;
  right: -35px;
    top: 20px;
  cursor:pointer;
  padding: 10px;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0;
  -webkit-transform:translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.nav-toggle:focus {
  outline-width: 0;
}
.btn_header {
  padding:12px 32px 12px 32px;
}
.nav-toggle [class*='bar-'] {
  background: #121127;
  display: block;
  -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
  -webkit-transition: .2s ease all;
   transition: 500ms ease all;
  border-radius: 8px;
  height: 2px;
  width: 28px;
  margin-bottom: 6px;
}
.nav-toggle .bar-bot {
  margin-bottom: 0;
}

.opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 13% 200%;
    transform-origin: 13% 200%;
    transition:all 500ms ease;
}
.opened .bar-mid {
  opacity: 0;
}
.opened .bar-bot {
  -webkit-transform: rotate(45deg);
          transform: rotate(-45deg);
-webkit-transform-origin: 16% -90%;
    transform-origin: 16% -90%;
    transition:all 500ms ease;
}
@media (max-width:1192px) {
  #navigation li {
    margin:0 20px 0 0;
  }
}
@media (max-width:1200px) {
  .logo{
    bottom:0px;
  }
  #navigation .hide_menu{
    display:none;
  }
    #navigation{
    background:#fff;
    padding:5px 0px 5px 0px
  }
  .nav-toggle{
    display:block;
    background: #fff;
  }
  .main_nav {
    padding: 15px 20px;
  }
  #navigation ul {
        padding: 0;
    display: block;
    position: fixed;
    z-index: 1;
    background: #fff;
    top: 53px;
    width: 250px;
    overflow: hidden;
    left: -290px;
    bottom: 0px;
    transition: all 500ms ease;
    height: 100%;
  }
  #navigation .active{
    padding: 0;
    display: block;
    position: fixed;
    z-index: 1;
    background: #fff;
    top: 53px;
    width: 250px;
    overflow: hidden;
    left: 0px;
    bottom: 0px;
    transition: all 500ms ease;
    height: 100%;
    padding-left: 29px;
  }
  #navigation ul li {
    display:block;
    margin-top:15px;
    color: #121127;
    font-size:16px;
  }
  #navigation ul li a{
  color:#121127;
  }
  .btn_header_desk {
    display:none;
  }
  .btn_header_mobile {
    display:block;
    float:left;
    margin-top:10px;
  }
} 
</style>