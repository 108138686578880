<template>
	<footer>
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-6 col-sm-6">
					<router-link to="/"><p class="header_logo">Nimirum</p></router-link>
					<p class=""></p>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 class="">О нас</h3>
					<ul>
						<!-- <li><a href="">Product Engineering</a></li>
						<li><a href="">Product Engineering</a></li>
						<li><a href="">Product Engineering</a></li> -->
						<li>Наша компания — эксперт в области IT дизайна и разработки ботов.</li>
					</ul>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 class="">Наша задача</h3>
					<ul>
						<li> 
Мы помогаем бизнесам улучшать взаимодействие с клиентами и оптимизировать процессы с помощью инновационных решений.</li>
					</ul>	
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
				<h3 class="">Разработчик</h3>
					<ul>
						<li><span>Все права защищены ©2023.</span></li>
						<li>&nbsp</li>
						<li>Сайт создан и разработан <span>NIMIRUM</span>.</li>
					</ul>	
				</div>
				<div class='img_map'><img :src="require('@/assets/img/footer/footer_img.png')" alt=""></div>				
			</div>
			<div class="lines">
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
			</div>
		</div>
<!-- 		<div class="copyright">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-sm-6">
							<p>Copyright @2021</p>
					</div>
					<div class="col-sm-6">
						<ul>
							<li><a href=""> Privacy </a></li>
							<li> - </li>
							<li><a href=""> PolicyTerms & Conditions </a></li>
						</ul>												
					</div>								
				</div>
			</div>
		</div>	 -->	
	</footer>
</template>
<script>
	export default {

	};
</script>

<style scoped>
a {
	text-decoration:none;
}
footer {
	background-color: #1B0B3D;
	position:relative;
	padding-top:8%;
	color:#fff;
	position:relative;
	z-index:1;
	padding-bottom: 80px;
}
footer:before {
	content:'';
	position: absolute;
	width:100%;
	height:1px;
	top:0;
	background:hsla(0,0%,100%,.1);
}
footer .header_logo {
	color: #fff;
    text-decoration: none;
    font-size: 28px;
    position: relative;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    line-height: 44px;
    width: 134px;
}
footer p {
	font-size: 14px;
}
footer h3 {
	margin-bottom: 23px;
    color: #fff;
    position: relative;
    padding-bottom: 6px;
    font-size: 23px;
}
footer h3:before {
	content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background: #444BD3;
}
footer ul {
	list-style-type:none;
	padding:0px;
	position:relative;
	z-index:10;
}
footer ul li {
	margin-bottom: 12px;
    color: #fff;
    font-size: 14px;
}
footer ul li a {
	text-decoration:none;
	color:#fff;
}
footer ul li a:hover {
	color:#444BD3;
}
footer ul li span {
	font-weight:bold;
}
.img_map{
    position: absolute;
    right: 18%;
    top:20%;
}
.img_map img {
	max-width:100%;
	height:auto;
}
.copyright {
	background-color: #444BD3;
    margin-top: 6%;
    padding-top: 5px;
    padding-bottom: 5px;
}
.copyright p {
	margin-top:0px;
}
.copyright a{
	text-decoration:none;
	color:#fff;
}
footer .copyright ul li{
	display:inline-block;
	margin-left:5px;
}
footer .copyright ul{
	text-align:right;
}
.copyright a:hover {
	color:#ff4800;
}
.lines {
	left: 0;
    right: 0;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:first-child {
    margin-left: -25%;
}
.lines .line {
    width: 1px;
    left: 50%;
    background: hsla(0,0%,100%,.1);
    overflow: hidden;
}
.lines, .lines .line {
    position: absolute;
    top: 0;
    height: 100%;
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,0)),color-stop(75%,#fff),to(#fff));
    background: -webkit-linear-gradient(top,hsla(0,0%,100%,0),#fff 75%,#fff);
    background: linear-gradient(
180deg
,hsla(0,0%,100%,0) 0,#fff 75%,#fff);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(.4,.26,0,.97);
    animation-timing-function: cubic-bezier(.4,.26,0,.97);
}
.lines .line:first-child:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line:nth-child(3):after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;	
}
@keyframes run {
	0%{
		top:-50%;
	}
	100%{
		top:110%;
	}
}
@media (max-width:576px) {
.copyright p {
	margin-top:0px;
	text-align:center;
}
footer .copyright ul {
	text-align:center;
}
}
</style>