<template>
  <div class="home_info background_gradient">
    <img :src="require('@/assets/img/icon_animate/dot3.png')" alt="" class="icon_animate_1">
    <img :src="require('@/assets/img/icon_animate/shape3.png')" alt="" class="icon_animate_2">
    <img :src="require('@/assets/img/icon_animate/shape2.svg')" alt="" class="icon_animate_3">
    <img :src="require('@/assets/img/icon_animate/shape5.svg')" alt="" class="icon_animate_4">
    <img :src="require('@/assets/img/icon_animate/dot1.png')" alt="" class="icon_animate_5">
        <div class="box">
          <div class="row">
            <div class="col-lg-5">
              <div class="home_info_box">
                <h3 class="home_h3 text-color">Разработка «под ключ» и готовые решения для бизнеса</h3>
                <p class="home_p text-color">От небольшого модуля до разработки корпоративного портала</p>
                <a class="home_btn" @click="this.$store.state.feedback_form_toggle = true">Узнать стоимость</a>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="banner_shape_block">
                <img :src="require('@/assets/img/animate_header/banner-2-shape1.png')" alt="" class="image_banner shape_1">
                <img :src="require('@/assets/img/animate_header/banner-2-shape3.png')" alt="" class="image_banner shape_2">
                <img :src="require('@/assets/img/animate_header/banner-2-shape4.png')" alt="" class="image_banner shape_3">
                <img :src="require('@/assets/img/animate_header/banner-2-shape5.png')" alt="" class="image_banner shape_4">
                <img :src="require('@/assets/img/animate_header/banner-2-shape6.png')" alt="" class="image_banner shape_5">
                <img :src="require('@/assets/img/animate_header/banner-2-shape7.png')" alt="" class="image_banner shape_6">
                <img :src="require('@/assets/img/animate_header/banner-2-shape8.png')" alt="" class="image_banner shape_7">
                <img :src="require('@/assets/img/animate_header/banner-2-shape2.png')" alt="" class="image_banner shape_8">
              </div>
              <div>
                <img :src="require('@/assets/img/banner-image_mobile.png')" alt="" class="banner_shape_block_mobile">
            </div>
            </div>
        </div>             
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
.box {
  position: relative;
  overflow: hidden;
}
.background_gradient {
  position: relative;
}
/*****home_info******/
.home_info_box {
  margin-top:150px;
  padding-bottom:126px;
}
.home_h3 {
  font-size:48px;
  line-height:120%;
  font-weight:500;
  margin:0;
}
.home_p {
  font-size:26px;
  margin:39px 0 76px 0;
}
.home_btn {
  padding: 13px 102px;
  background-color:#444BD3;
  color:#fff;
  border-radius:6px;
}
.banner_shape_block {
    width: 600px;
    height: 487px;
    position: relative;
    top: 130px;
    left:-0%;
}
.banner_shape_block_mobile {
    display:none;
}
.image_banner {
  position: absolute;
}
.shape_1{
    z-index: 5;
    top: 30%;
    left: 35%;
    animation:shape_1_animate infinite 5s linear;
}
@keyframes shape_1_animate{
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform:rotate(360deg)
    }
}
.shape_2{
    left: 74%;
    top: 32%;
    animation:Fade_in_shape2 1s ease;
}
@keyframes Fade_in_shape2 {
    0%{
        opacity:0;
        top:-50px;
    }
    100%{
        opacity:1;
        top:32%;
    }
}
.shape_3{
    top: 63%;
    z-index: 4;
    left: 6%;
    animation:Fade_in_shape3 1s ease;    
}
@keyframes Fade_in_shape3 {
    0%{
        opacity:0;
        left:-50px;
    }
    100%{
        opacity:1;
        left:6%;
    }
}
.shape_4{
    top: 38%;
    left: 22%;
    z-index: 3;
    animation:Fade_in_shape4 1s ease;     
}
@keyframes Fade_in_shape4 {
    0%{
        opacity:0;
        left:-50px;
    }
    100%{
        opacity:1;
        left:22%;
    }
}
.shape_5{
    top: 33%;
    left: 5%;
    animation:Fade_in_shape5 1s ease;    
}
@keyframes Fade_in_shape5 {
    0%{
        opacity:0;
        top:-50px;
    }
    100%{
        opacity:1;
        top:33%;
    }
}
.shape_6{
    left: 45%;
    top: -5%;
    animation:Fade_in_shape6 1s ease;    
}
@keyframes Fade_in_shape6 {
    0%{
        opacity:0;
        top:-50px;
    }
    100%{
        opacity:1;
        top:-5%;
    }
}
.shape_7{
    top: 50%;
    left: 20%;
    animation:Fade_in_shape7 1s ease;     
}
@keyframes Fade_in_shape7 {
    0%{
        opacity:0;
        left:-50px;
    }
    100%{
        opacity:1;
        left:20%;
    }
}
.shape_8 {
    top: 38%;
    left: 31%;
    z-index: 4;
    animation:Fade_in_shape8 2s ease;     
}
@keyframes Fade_in_shape8 {
    0%{
        opacity:0;
    }
    50%{
        opacity:0;    
    }
    100%{
        opacity:1;
    }
}
.icon_animate_1 {
    position: absolute;
    left: 50%;
    top: 50%;
    animation:icon_animate_1 infinite 90s linear;
}
@keyframes icon_animate_1 {
    0%{
        top:50%;
        left:50%;
    }
    50%{
        top:40%;
        left:10%;
    }
    100%{
        top:20%;
        left:70%;
    }
}
.icon_animate_2 {
    position: absolute;
    opacity: 0.3;
    right: 0;
    top: -15px;
    width:202px;
    height:202px;
    animation:icon_animate_2 infinite 2s linear;    
}
@keyframes icon_animate_2 {
    0%{
    transform:rotateY(0deg);    
    }
    50%{
    transform:rotateY(90deg); 
    }
    100%{
    transform:rotateY(0deg); 
    }
}
.icon_animate_3 {
    position: absolute;
    top: 2.5%;
    left: 5%;
    animation:icon_animate_3 infinite 8s linear;    
}
@keyframes icon_animate_3 {
    0%{
    transform:rotate(0deg);    
    }
    100%{
    transform:rotate(360deg); 
    }
}
.icon_animate_4 {
    position: absolute;
    top: 35%;
    right: 6%;
    animation:icon_animate_3 infinite 8s linear;    
}
.icon_animate_5 {
    position: absolute;
    left: 50%;
    top: 5%;
    animation:icon_animate_5 infinite 90s linear;
}
@keyframes icon_animate_5 {
    0%{
        top:5%;
        left:50%;
    }
    50%{
        top:30%;
        left:30%;
    }
    100%{
        top:50%;
        left:80%;
    }
}

@media(max-width:991px) {
  .home_info_box {
    padding-bottom:0px;
    margin-top:15%;
  }
  .banner_shape_block_mobile {
    display:none;
    width:80%;
    margin:0 auto;
  }
  .banner_shape_block {
    left: 10%;
  }
  .home_h3 {
    font-size:35px;
  }
  .home_p {
    font-size: 19px;
    margin: 35px 0 55px 0;
  }
  .home_btn {
    padding: 13px 50px;
  }
}
@media(max-width: 600px){
    .banner_shape_block {
    left: -23%;
  }
}
/*****home_info*****/
</style>