<template>
  <div class="head black">
  	<heade/>
  </div>
  <div class="services">
  		<our_services/>
  		<information_block/>
  </div>
  <div class="euclid">
  	<our_work/>
  	<box_success/>
  	<our_skill/>
  </div>
  <contactUs/>
  <foot/>
  <politic/>
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import heade from '@/components/home_info.vue'
import our_services from '@/components/our_services.vue'
import information_block from '@/components/information_block.vue'
import our_work from '@/components/our_work.vue'
import box_success from '@/components/box_success.vue'
import our_skill from '@/components/our_skill.vue'
import contactUs from '@/components/contactUs.vue'
import foot from '@/components/footer.vue'
import politic from '@/components/politic.vue'
export default {
  name:'',
  components: {
  	heade,
  	our_services,
  	information_block,
  	our_work,
  	box_success,
  	our_skill,
  	contactUs,
  	foot,
    politic,
  },
  data(){
  	return{
  	}
  },
  mounted(){
  },
  created(){
  	
  },
  methods:{
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
	}
};
</script>

<style scoped>
.euclid {
	position: relative;
	display:block;
}
.services {
background: url(../assets/img/back-desctop.svg);
background-repeat: no-repeat;
margin-top:5%;
position: relative;
}
/*.services:before {
	content:'';
	height:100%;
	width:100%;
	z-index: -1;
	background: linear-gradient(180deg, #2A1061 -0.03%, #1B0B3D 99.97%);
	transform: skewY(-3deg);
	display:block;
	position: absolute;
}*/
@media(max-width:1000px){
.services {
background: none;
background-size:contain;
background-repeat: no-repeat;
}
}	
</style>

