<template>
  <div class="feedback_form" :class="{'close-form':!this.$store.state.feedback_form_toggle, 'open-form':this.$store.state.feedback_form_toggle}">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-6 offset-md-3">
  				<form class="feedback_forme">
  					<i class="close" @click="this.$store.state.feedback_form_toggle = false"><span class="auto"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7443 1.13863L10.8605 0.254883L5.99988 5.11613L1.13863 0.254883L0.254883 1.13863L5.11613 5.99988L0.254883 10.8605L1.13863 11.7443L5.99988 6.88363L10.8605 11.7443L11.7443 10.8605L6.88363 5.99988L11.7443 1.13863Z" fill="black"/>
</svg></span></i>
  					
  					<img src="../assets/img/feedback_form/form_img.svg" alt="" class="form-img">
  					<div class="form-tittle" >Свяжитесь с нами </div>
  					<p class="form-text">Пожалуйста, укажите свое имя и номер телефона в форме обратной связи ниже. Мы свяжемся с вами в кратчайшие сроки и ответим на все ваши вопросы. Спасибо, что обратились к нам!</p>
				    	<span class="p-float-label mt-5">
						    <InputText :class="{'p-invalid':this.validator}" id="username" v-model="form.name" :disabled="!form.politic"/>
						    <label for="username">Имя</label>
						    <InlineMessage v-if="this.validator" severity="error"></InlineMessage>
						</span>
						<span class="p-float-label mt-5">
				    	<InputMask id='phone' :class="{'p-invalid':this.validator}" :disabled="!form.politic" v-model="form.phone" date="phone" mask="+7 (999) 999-9999" placeholder="+7 (999) 999-9999" />
				    	<label for="phone">Телефон</label>
				    	<InlineMessage  v-if="this.validator" severity="error"></InlineMessage>
				    	</span>

				    	<div class="flex-checkbox">
				    		<Checkbox v-model="form.politic" :binary="true"/>
				    		<p>Я принимаю условия <a @click="this.$store.state.show_politic = true" class="politic">пользовательского соглашения</a></p>
				    	</div>
				    	<a href="#0" type="submit" label="Submit" @click="sendData()" class="btn-sub">Отправить</a>		    	
  				</form>
  				<Toast />
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import { useToast } from 'primevue/usetoast';
import InlineMessage from 'primevue/inlinemessage';
export default {

  name:'',
  components: {
  	InputMask,
  	InputText,
  	Dialog,
  	Checkbox,
  	InlineMessage,
  },
  data(){
  	return{
  		form:{
  			phone:'',
  			name:'',
  			politic:true,
  		},
  		visible:false,
  		validator:false,
  		toast:useToast(),
  	}
  },
  mounted(){
	this.show()
  },
  created(){
  	
  },
  methods:{
  	show(){
	    
	    console.log('tt')
	},
	sendData(){
		console.log(this.form)
		if(this.form.politic == true){
			
			if (this.form.name.length < 2 || this.form.phone.length < 8){
				console.log(this.form.phone.length)
				this.$toast.add({ severity: 'error', summary: 'Ошибка!', detail: 'Пожалуйста, укажите ваше имя и телефон.', life: 3000 });
				this.validator = true
				setTimeout(()=>{this.validator = false}, 3000)
			} else {
				console.log(this.form.phone.length)
				this.$toast.add({ severity: 'success', summary: 'Спасибо!', detail: 'Ваша заявка успешно отправлена! Мы с вами свяжемся в ближайшее время.', life: 3000 });
				this.clearData()
			}
		} else {
			this.$toast.add({ severity: 'error', summary: 'Ошибка!', detail: 'Нажмите на галочку, чтобы принять пользовательское соглашение.', life: 3000 });
		}
		
	},
	clearData(){
		this.form = {
  			phone:'',
  			name:'',
  			politic:true,
  		}
	},
  	...mapMutations({
	}),
	...mapActions({
	}),
  },
  computed:{
  	...mapGetters([
	]),
  } 
};
</script>

<style scoped>
.feedback_form {
	font-family: 'Montserrat';
	position: fixed;
	z-index: 16;
	background:rgba(0,0,0, .3);
	width:100%;
	height:100%;
	top:0;
	left:0;
	bottom:0;
    right:0;
    display:flex;
    align-items: center;
}
.mt-5 {
	margin-top: 25px;
}
.mt-2{
	margin-top: 2%;
}
.p-inputtext {
	font-family: 'Montserrat';
	color:#000;
	border: 1px solid #D9D8FC;
}
.p-float-label > label {
	color:#000;
}
.form-img {
	width:100%;
	height:auto;
	max-height: 191px;
	margin-top: 35px;
}
.form-text {
	color: #111111;
	font-size: 15px;
	max-width: 250px;
}
.feedback_forme {
	border: 1px solid #D9D8FC;
	border-radius: 20px;
	padding:25px;
	display:flex;
	flex-direction: column;
    align-items: center;
    max-width:550px;
    background:#fff;
    position: relative;

}
.close-form {
	transform: rotateX(90deg);
	transition: all 500ms ease;
}
.open-form {
	transform: rotateX(0deg);
	transition: all 500ms ease;
}
.form-tittle {
	font-size: 25px;
	color:#000;
	margin:15px 0px;
	position: relative;
	margin-bottom: 0px;
}
.close {
	width:40px;
	height:40px;
	border-radius: 100px;
	transition: all 500ms ease;
	display:flex;
	position: absolute;
	top:20px;
	right:15px;
	cursor:pointer;
	z-index: 1;
	background:#fff;
	align-items: center;
	border:1px solid #000;
}
.auto{
	margin:0 auto;
}
.close:hover {
/*	background:#6c757d;*/
	transition: all 500ms ease;
}
.btn-sub {
	background: #444BD3;
	border-radius: 10px;
	color:#fff;
	width:100%;
	padding:12px 0px;
	outline:none;
	max-width:230px;
	font-size: 16px;
	margin-top:5px;
	border:none;
	font-family: 'Montserrat';
	text-align: center;
	text-decoration: none;
}
.flex-checkbox {
	max-width: 220px;
	display:flex;
	align-items: center;
}
.flex-checkbox p {
	color:#000;
	margin-left: 10px;
}
.flex-checkbox a {
	color:#444BD3;
	text-decoration: none;
	display:block;
}
@media(max-width: 900px) {
	.form-text {
		display:none;
	}
}
</style>

